// TODO: TS refactoring
import React, { useState, useCallback, useRef } from 'react';
import propTypes, { oneOfType } from 'prop-types';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

import { InputButton } from '@periodica/ui-kit';

import useCombinedRefs from '../../hooks/useCombinedRefs';
import Modal from '../legacy/Modal/Modal';

import radioEmpty from '../../images/icons/radioEmpty.svg';
import radioSelected from '../../images/icons/radioSelected.svg';
import arrow from '../../images/icons/selector-arrow-down.svg';

import styles from './SumSelector.module.scss';

const sumFieldProductType = propTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  image: propTypes.object,
  title: propTypes.string,
  description: propTypes.string,
});

const sumFieldOptionType = propTypes.shape({
  id: propTypes.string.isRequired,
  sum: propTypes.number.isRequired,
  products: propTypes.arrayOf(sumFieldProductType),
});

function Product({ image, title, subtitle }) {
  return (
    <div className={styles.product}>
      <GatsbyImage
        className={styles.productImage}
        alt="product icon"
        image={image.localFile.childImageSharp.gatsbyImageData}
      />
      <div className={styles.productMetadata}>
        <p className={styles.productName}>{title}</p>
        <p className={styles.productDescription}>{subtitle}</p>
      </div>
    </div>
  );
}

Product.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  image: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  description: propTypes.string,
};

Product.defaultProps = {
  description: '',
};

function Option({ sum, products, active, onClick, optionIndex }) {
  const radioIcon = active ? radioSelected : radioEmpty;

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  return (
    <div className={cn({ [styles.option]: true, [styles.active]: active })} onClick={onClick}>
      <div className={styles.optionSum}>
        <img className={styles.optionSumIcon} alt="product icon" src={radioIcon} />
        <div className={styles.optionSumTitle}>
          {sum}
          &nbsp;₽
        </div>
      </div>
      <div className={styles.optionTitle}>Можно купить:</div>
      <div className={styles.optionProducts}>
        {products.map((item, idx) => {
          if (idx > 2) return null;
          return (
            <Product
              key={item.id}
              title={item.title}
              subtitle={item.pageCount}
              image={item.image}
            />
          );
        })}
        {optionIndex > 0 && <span>Или любой продукт дешевле</span>}
      </div>
    </div>
  );
  /* eslint-enable */
}

Option.propTypes = {
  sum: propTypes.number.isRequired,
  active: propTypes.bool,
  products: propTypes.arrayOf(sumFieldProductType).isRequired,
  onClick: propTypes.func.isRequired,
  optionIndex: propTypes.number.isRequired,
};

Option.defaultProps = {
  active: false,
};

const SumSelector = React.forwardRef(function SumSelector(
  { onChange, options, value, error },
  ref
) {
  const [showModal, setShowModal] = useState(false);
  const [currentChoice, setCurrentChoice] = useState(value);

  const handleOpen = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleSelect = useCallback(
    (v) => () => {
      setCurrentChoice(v);
    },
    []
  );

  const handleClose = useCallback(() => {
    setShowModal(false);
    setCurrentChoice(value);
  }, [value]);

  const handleConfirm = useCallback(() => {
    onChange(currentChoice);
    setShowModal(false);
  }, [currentChoice, onChange]);

  const inputRef = useRef();
  const inputEl = useCombinedRefs(inputRef, ref);

  return (
    <div className={styles.sumWrapper}>
      <InputButton
        ref={inputEl}
        buttonProps={{ autoComplete: 'off', readOnly: true }}
        name="sum"
        error={error}
        onClick={handleOpen}
        value={
          value?.sum ? <span>{value?.sum} ₽</span> : <span className={styles.nullColor}>0 ₽</span>
        }
        icon={<img alt="button icon" src={arrow} className={styles.arrow_icon} />}
      />
      <Modal
        show={showModal}
        onClose={handleClose}
        header="Номинал"
        onConfirm={handleConfirm}
        confirmValue={!!currentChoice?.sum}
        currentProductId={currentChoice?.id}
      >
        <div className={styles.modalContent}>
          {options.map((item, index) => (
            <Option
              active={item.sum === currentChoice?.sum}
              onClick={handleSelect(item)}
              key={`option-${item.id}`}
              sum={item.sum}
              products={item.products}
              optionIndex={index}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
});

SumSelector.propTypes = {
  options: propTypes.arrayOf(sumFieldOptionType).isRequired,
  onChange: propTypes.func.isRequired,
  error: propTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: oneOfType([propTypes.string, propTypes.object, propTypes.number]),
};

SumSelector.defaultProps = {
  value: undefined,
  error: undefined,
};

export default SumSelector;
